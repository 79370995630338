import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// components
import { ToastProvider } from "react-toast-notifications";
import { useFetchSrcmApi } from "gatsby-plugin-hfn-profile/fetch-srcm-api";
import Breadcrumb from "../Breadcrumb";
import RegisterForm from "./form/register/RegisterForm";
import Layout from "../../layouts/Layout";

export default function Register({ eventId }) {
  const [eventDetail, setEventDetail] = useState({
    title: "",
  });
  const { fetchSrcmApi } = useFetchSrcmApi();

  const breadCrumbs = [
    {
      id: 1,
      label: "Home",
      url: "/",
    },
    {
      id: 2,
      label: "Event Details",
      url: `/events/${eventId}/`,
    },
    {
      id: 3,
      label: "Register",
      url: `/events/${eventId}/register-new`,
    },
  ];

  useEffect(() => {
    const getEventDetails = async () => {
      await fetchSrcmApi({
        api: `/api/v2/events/${eventId}/`,
        client: "eventsClient",
      }).then((eventResponse) => {
        setEventDetail(eventResponse);
      });
    };
    getEventDetails();
  }, [eventId, fetchSrcmApi]);

  return (
    <Layout
      pageName="event-registration-page"
      headerTitle={eventDetail.title}
      sidebarVisible={false}
    >
      <Breadcrumb items={breadCrumbs} />
      <ToastProvider placement="bottom-right">
        <RegisterForm eventId={eventId} eventDetails={eventDetail} />
      </ToastProvider>
    </Layout>
  );
}

Register.propTypes = {
  eventId: PropTypes.string.isRequired,
};
