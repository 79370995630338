const timeOptions = [
  { name: "00am-02am", label: "Midnight to 2AM" },
  { name: "02am-04am", label: "2AM to 4AM" },
  { name: "04am-06am", label: "4AM to 6AM" },
  { name: "06am-08am", label: "6AM to 8AM" },
  { name: "08am-10am", label: "8AM to 10AM" },
  { name: "10am-12pm", label: "10AM to Noon" },
  { name: "12pm-02pm", label: "Noon to 2PM" },
  { name: "02pm-04pm", label: "2PM to 4PM" },
  { name: "04pm-06pm", label: "4PM to 6PM" },
  { name: "06pm-08pm", label: "6PM to 8PM" },
  { name: "08pm-10pm", label: "8PM to 10PM" },
  { name: "10pm-11pm", label: "10PM to Midnight" },
];

const registrationOptions = {
  fields: [
    {
      name: "arrival_date",
      label: "Arrival Date",
      type: "date",
      minDate: Date.now(),
    },
    {
      name: "arrival_time",
      label: "Arrival Time",
      type: "select",
      options: timeOptions,
      props: {
        style: {
          border: "1px solid #eeeeee",
        },
      },
    },
    {
      name: "departure_date",
      label: "Depature Date",
      type: "date",
      props: {
        style: {
          alignItems: "center",
          placeItems: "center",
        },
      },
    },
    {
      name: "departure_time",
      label: "Depature Time",
      type: "select",
      options: timeOptions,
      props: {
        style: {
          border: "1px solid #eeeeee",
        },
      },
    },
    { name: "trainer_name", label: "Trainer Name", type: "text" },
    { name: "trainer_number", label: "Trainer Number", type: "phone" },
    {
      name: "emergency_contact_name",
      label: "Emergency Contact Name",
      type: "text",
    },
    {
      name: "emergency_contact_number",
      label: "Emergency Contact Number",
      type: "phone",
    },
  ],
  validator: [],
};

export default registrationOptions;
