import { get } from "lodash";
import { validateRE } from "sites-common/utils/validations";
import { displayError, displayAlert } from "sites-common/utils/customErrors";

function formatOdooDate2Sys(dstr) {
  if (dstr !== undefined && dstr !== null && dstr !== "" && dstr !== false) {
    try {
      const parts = dstr.substring(0, 10).split("-");
      return new Date(
        parseInt(parts[0], 10),
        parseInt(parts[1], 10) - 1,
        parseInt(parts[2], 10)
      );
    } catch (e) {
      displayError(e);
    }
  }
  return "";
}

function daysDiff(str1, str2) {
  const first = formatOdooDate2Sys(str1);
  const second = formatOdooDate2Sys(str2);
  const diff = Math.round((first - second) / (1000 * 60 * 60 * 24));
  return diff;
}

// date(Dec 31, 2019 23:01:59) => str(2019-12-31)
function formatSys2OdooDate(date) {
  try {
    const d = date.getDate();
    const m = date.getMonth() + 1; // Month from 0 to 11
    const y = date.getFullYear();
    const pad = (x) => (x <= 9 ? `0${x}` : x);
    return `${y}-${pad(m)}-${pad(d)}`;
  } catch (e) {
    return null;
  }
}

function computeRule(ruleX, state, at_element) {
  // format of a rule
  // [ [":variable", "+", "string"], "-", 27 ]
  // state is dict containing :variable as a key
  const date_pattern = /^\d\d\d\d-\d\d-\d\d$/;
  let root = [];
  if (typeof at_element === "string") {
    root = [at_element];
  } else if (Array.isArray(at_element)) {
    root = [...at_element];
  }
  root.splice(root.length - 1, 1);
  let process;
  const getval = (val) => {
    if (Array.isArray(val)) {
      return process(val);
    }
    if (val instanceof Date) {
      return daysDiff(formatSys2OdooDate(val), "1980-01-01");
    }
    if (typeof val === "string") {
      if (val.startsWith(":")) {
        let v;
        if (val.startsWith(":~")) {
          v = val.substring(2);
          v = [...root, v];
        } else {
          v = val.substring(1);
        }
        if (v === ":TODAY") {
          return daysDiff(formatSys2OdooDate(new Date()), "1980-01-01");
        }
        if (v === ":TODAY_SHORT") {
          return formatSys2OdooDate(new Date());
        }
        if (v === ":TODAY_YEAR") {
          return new Date().getFullYear();
        }
        if (date_pattern.test(get(state, v, ""))) {
          return daysDiff(get(state, v), "1980-01-01");
        }
        return get(state, v);
      }
      if (date_pattern.test(val)) {
        return daysDiff(val, "1980-01-01");
      }
      return val;
    }
    return val;
  };

  process = (rule) => {
    const [rule1, rule2, rule3] = rule;
    const a = getval(rule1);
    const op = rule2;
    const b = getval(rule3);

    if (!a) {
      return null;
    }

    // console.log("a", a, "op", op, "b", b);
    switch (op) {
      case "IS":
        if (b === "SET" || b === "NOTNULL") {
          return !!a;
        }
        if (b === "EMPTY" || b === "NULL") {
          return !a;
        }
        if (b === "ARRAY") {
          return Array.isArray(a);
        }
        return a === b;

      case "IN":
        if (typeof b === "string") {
          return b.search(a) > -1;
        }
        if (typeof b === "object") {
          return a in b;
        }
        return null;

      case "LIKE":
        return validateRE(b, a);
      case "+":
        return a + b;
      case "-":
        return a - b;
      case "/":
        return Math.round(a / b);
      case "*":
        return a * b;
      case "%":
        return a % b;
      case ">":
        return a > b;
      case ">=":
        return a > b;
      case "<":
        return a < b;
      case "<=":
        return a <= b;
      case "==":
        return a === b;
      case "!=":
        return a !== b;
      case "&&":
        return a && b;
      case "||":
        return a || b;
      case "XOR":
        return a ? !b : b;
      case "XAND":
        return a ? b : !b;
      case "=>": // implies operator
        return a ? b : true;
      case "IF":
        if (b) {
          return a;
        }
        return false;

      case "ELSE":
        if (a) {
          return a;
        }
        return b;

      case "SWITCH_DICT":
        if (typeof a === "string" && typeof b === "object") {
          if (a in b) {
            return b[a];
          }
          if ("##DEFAULT##" in b) {
            return b["##DEFAULT##"];
          }
          return "##IGNORE##";
        }
        return "##IGNORE##";
      default:
        return "ERROR";
    }
  };

  return process(ruleX);
}

function validateConstraints(constraints, state) {
  const errors = [];
  if (!state) {
    displayAlert("Something Wrong");
  }
  try {
    constraints.forEach((constraint) => {
      const { conds, msg } = constraint;
      if (!conds || !state) {
        errors.push("Invalid Constraints");
      }
      const val = computeRule(conds, state, []);
      // console.log( "cond", msg, conds, cidx, val);

      if (val === false) {
        errors.push(msg);
      } else {
        // do nothing
      }
    });
  } catch (error) {
    errors.push(`Invalid Constraints: ${error}`);
  }
  return errors;
}

const generateRestrictionsJson = (constraints) => {
  const resJson = [];
  constraints.forEach((constraint) => {
    const { condtion, field_type, error_msg, value } = constraint;
    const fType = `:${field_type}`;
    const resObj = {
      conds: [fType, condtion, value],
      msg: error_msg,
    };
    resJson.push(resObj);
  });
  return resJson;
};

const checkRestrictions = (resCons, resState) => {
  const rJson = generateRestrictionsJson(resCons);
  const r = validateConstraints(rJson, resState);
  return r;
};

export function new_pnr(name) {
  const d = new Date();
  const n = d.getTime() - 1520000000000;
  let out = "";
  let itr = n;
  while (itr > 0) {
    const div = Math.floor(itr / 26);
    const rem = itr % 26;
    out = String.fromCharCode(65 + rem) + out;
    itr = div;
  }
  return `${name
    .replace("-", "")
    .replace("_", "")
    .replace(".", "")
    .replace("@", "")
    .slice(0, 2)
    .toUpperCase()}-${out.slice(0, 4)}-${out.slice(
    out.length - 4,
    out.length
  )}`;
}

export default checkRestrictions;
