export const regFormFields = [
  {
    type: "statichtml",
    label: "<h2>Registration Form<h2>",
    name: "x6",
  },
  {
    name: "ref",
    label: "Abhyasi Id",
    type: "text",
    props: {
      required: false,
    },
  },
  {
    name: "name",
    label: "Name",
    type: "text",
    props: {
      required: true,
    },
  },
  {
    name: "city_id",
    label: "City",
    type: "city",
    props: {
      required: false,
    },
  },
  {
    name: "email",
    label: "Email",
    type: "email",
    props: {
      required: true,
    },
  },
  {
    name: "mobile",
    label: "Mobile (Eg: +918888888888)",
    type: "phone",
    props: {
      required: true,
    },
  },
];

export const regFormValidator = [];

export const regFormDefaults = {
  ref: "",
  event: "",
  pnr: "",
  name: "",
  email: "",
  mobile: "",
  city_id: null,
  reg_json: {},
  communication_preference: 0,
};
