import React from "react";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import { BarLoader } from "react-spinners";

/** A Bar spinner */
export const MySpinnerWithoutMemo = () => (
  <Stack horizontal horizontalAlign="start">
    <BarLoader height="2" width="100%" />
  </Stack>
);

export const MySpinner = React.memo(MySpinnerWithoutMemo);
