/** @jsx jsx */
import { jsx, Box, Button, Badge } from "theme-ui";
import { useState, useEffect, useCallback } from "react";
import { get } from "lodash";
import { useFetchSrcmApi } from "gatsby-plugin-hfn-profile/fetch-srcm-api";
import { useAlert } from "gatsby-plugin-hfn-profile/alert";
import PropTypes from "prop-types";
import Breadcrumb from "../Breadcrumb";
import Layout from "../../layouts/Layout";
import { MySpinner } from "../Spinner";

export default function Summary({ pnr }) {
  const { fetchSrcmApi } = useFetchSrcmApi();
  const [registrations, setRegistrations] = useState([]);
  const [eventDetail, setEventDetail] = useState({});
  const [updating, setUpdating] = useState(false);
  const { showAlert } = useAlert();

  const getEventDetail = useCallback(
    (eventName) => {
      fetchSrcmApi({
        api: `/api/v2/events/${eventName}/`,
        client: "eventsClient",
      }).then((data) => {
        setEventDetail(data);
      });
    },
    [fetchSrcmApi]
  );

  const getRegistrations = useCallback(() => {
    fetchSrcmApi({
      api: `/api/v2/event-registrations/?pnr=${pnr}`,
      client: "eventsClient",
    }).then((data) => {
      setRegistrations(data.results);
      if (data.results && data.results.length !== 0) {
        getEventDetail(data.results[0].event);
      }
    });
  }, [pnr, getEventDetail, fetchSrcmApi]);

  useEffect(() => {
    getRegistrations();
  }, [pnr, fetchSrcmApi, getRegistrations]);

  const breadCrumbs = [
    {
      id: 1,
      label: "Home",
      url: "/",
    },
    {
      id: 2,
      label: "Registrations",
      url: `/events/registrations/${pnr}/`,
    },
  ];

  const onCancel = (itemId) => {
    const requestApi = {
      status: "cancelled",
    };

    setUpdating(true);
    const afterUpdate = () => {
      const alertInfo = {
        title: "Success",
        message: `Registration has been successfully cancelled.`,
        confirm_text: "Okay",
        is_blocking: true,
      };
      setUpdating(false);
      showAlert(alertInfo);
      getRegistrations();
    };

    fetchSrcmApi({
      api: `/api/v2/event-registrations/${itemId}/`,
      method: "PATCH",
      client: "eventsClient",
      methodParams: requestApi,
    })
      .then((data) => {
        afterUpdate();
        return data;
      })
      .catch(() => {
        const alertInfo = {
          title: "Error",
          message: `Sorry, Something went wrong. Please try again later.`,
          confirm_text: "Okay",
          is_blocking: true,
        };
        setUpdating(false);
        showAlert(alertInfo);
      });
  };

  const checkStatus = useCallback((status) => {
    switch (status) {
      case "cancelled":
        return "#0069d9";

      case "pending fees":
        return "#e0a800";

      case "approval declined":
        return "#dc3545";

      case "confirmed":
        return "#218838";

      default:
        return "#6c757d";
    }
  }, []);

  return (
    <Layout
      pageName="event-details-page"
      headerTitle="Heartfulness Event Details"
      sidebarVisible
    >
      <Breadcrumb items={breadCrumbs} />
      <Box sx={{ textAlign: "center", my: 3, fontSize: 3 }}>
        <h3>Registration Summary</h3>
      </Box>
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">
            <span>PNR</span>
          </h4>

          <div className="event-se-section">
            <span>{pnr}</span>
            <div>
              <b>{get(eventDetail, "title", "")}</b>
            </div>
          </div>
        </div>
        {updating && <MySpinner />}
        <div
          className="card-body"
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            overflowX: "scroll",
          }}
        >
          {registrations.length !== 0 ? (
            <table
              sx={{
                textAlign: "center",
                color: "#fff",
                fontSize: ["12px", "15px"],
                border: "1px solid #050c13",
              }}
              cellSpacing={0}
            >
              <thead sx={{ bg: "#050c13", td: { p: [2, 3] } }}>
                <tr>
                  <td>Name/Gender/Age</td>
                  <td>City</td>
                  <td>Status</td>
                  <td>Actions</td>
                </tr>
              </thead>
              <tbody sx={{ bg: "#fff", color: "#000", td: { p: 2 } }}>
                {registrations.map((regItem) => (
                  <tr
                    sx={{
                      td: { borderBottom: "1px solid #050c13" },
                      ":hover": { bg: "#dadada" },
                    }}
                    key={regItem.name}
                  >
                    <td>
                      {get(regItem, "name", "")}
                      <Badge
                        sx={{
                          bg: "#17a2b8",
                          mx: 2,
                        }}
                      >
                        {get(regItem, "gender", "")}
                      </Badge>
                      <Badge
                        sx={{
                          bg: "#17a2b8",
                          mx: 2,
                        }}
                      >
                        {get(regItem, "age_group", "")}
                      </Badge>
                    </td>
                    <td>{get(regItem, "city", "-")}</td>
                    <td>
                      {" "}
                      <Badge
                        sx={{
                          bg: checkStatus(regItem?.status),
                          mr: 2,
                          p: "3px 7px",
                        }}
                      >
                        {get(regItem, "status", "-")}
                      </Badge>
                    </td>

                    <td>
                      {/* <button
                     type="button"
                     sx={{
                       bg: "#de7c08",
                       color: "#fff",
                       p: "4px 6px",
                       borderRadius: "4px",
                       ml: `${regItem?.status === "cancelled" ? "-80px" : 0}`
                     }}
                   >
                     View Details
                   </button> */}
                      {regItem?.status !== "cancelled" && (
                        <Badge
                          sx={{
                            cursor: "pointer",
                            px: 3,
                            py: 1,
                            ml: [0, 3],
                            mt: [2, 0],
                            bg: "#6c757d",
                          }}
                          role="button"
                          disabled={updating}
                          onClick={() => onCancel(get(regItem, "id", null))}
                        >
                          Cancel
                        </Badge>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <Box sx={{ textAlign: "center", p: 3 }}>
              <h3>No registration summary found.</h3>
            </Box>
          )}
          <div sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
            <Button>Register More Participants</Button>
          </div>
        </div>
      </div>
      <Box
        sx={{
          borderRadius: "4px",
          border: "1px solid rgba(0,0,0,.125)",
          mt: "20px",
        }}
      >
        <h3
          sx={{
            bg: "rgba(0,0,0,.03)",
            p: 3,
            borderBottom: "1px solid rgba(0,0,0,.10)",
          }}
        >
          DONATE FOR A HEARTFUL HUMANITY
        </h3>
        <p sx={{ p: 3 }}>
          The Heartfulness organizations run on donations and grants, and are
          registered as charitable non-profit organizations. The Heartfulness
          movement is based on everyone voluntarily contributing and sharing,
          but there is no compulsion to donate. It all depends on your means and
          willingness.
        </p>
        <Button sx={{ m: "3", mt: 0 }}>Donate Now</Button>
      </Box>
    </Layout>
  );
}

Summary.propTypes = {
  pnr: PropTypes.string.isRequired,
};
