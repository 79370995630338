/** @jsx jsx */
import { jsx } from "theme-ui";

import { Router } from "@reach/router";

// components
import Details from "../components/events/Details";

import Register from "../components/events/Register";
import Summary from "../components/events/summary";
import VisitorRegistration from "../components/events/visitor-registration";

const Events = () => (
  <Router>
    <Details path="events/:eventId" />
    <Register path="events/:eventId/register-new/" />
    <Summary path="events/registrations/:pnr" />
    <VisitorRegistration path="events/visitor-registration/:pnr" />
  </Router>
);

export default Events;
