import React, { useEffect, useState } from "react";

// hfn components
import DynamicForm from "@heartfulnessinstitute/react-hfn-forms/dist/DynamicForm";
import { validateAbhyasiId } from "@heartfulnessinstitute/react-hfn-forms/dist/validations";
import { navigate } from "@reach/router";
import PropTypes from "prop-types";
// components
import { Button } from "theme-ui";
// utils
import generatePNR from "sites-common/utils/generatePNR";
import { get } from "lodash";
import { useToasts } from "react-toast-notifications";
import { useFetchSrcmApi } from "gatsby-plugin-hfn-profile/fetch-srcm-api";
import { regFormFields, regFormDefaults } from "./schema";
import checkRestrictions from "../../../../utils/eventRegRestrictions";

export default function RegisterForm({ eventId, eventDetails }) {
  const { addToast } = useToasts();
  const [registerFormFields, setRegisterFormFields] = useState([]);
  // const [dynamicFromFields, setDynamicFromFields] = useState([]);
  const { fetchSrcmApi } = useFetchSrcmApi();

  const submitButton = <Button type="submit">Register</Button>;

  const discardButton = (
    <Button
      type="button"
      ml={3}
      className="secondary"
      onClick={() => navigate(`/events/${eventId}`)}
    >
      Discard
    </Button>
  );

  const onSave = async (data, successCallback, failureCallback) => {
    try {
      const pnr = {
        pnr: generatePNR(data.email),
      };

      const eventName = {
        event: eventId,
      };

      const ref = {
        ref: "B99999999",
      };

      const bodyData = {
        ...data,
        ...pnr,
        ...eventName,
        ...ref,
      };

      let errorsRes = [];
      errorsRes = checkRestrictions(
        get(eventDetails, "event_json.constraints", []),
        bodyData
      );

      if (errorsRes.length === 0) {
        await fetchSrcmApi({
          api: `/api/v2/event-registrations/`,
          client: "eventsClient",
          methodParams: { ...bodyData },
          method: "POST",
        })
          .then((eventResponse) => {
            addToast(
              `You have been successfully registered. Your registration number is ${eventResponse.pnr}.`,
              {
                appearance: "success",
                autoDismiss: true,
                autoDismissTimeout: 6000,
                onDismiss: () => {
                  navigate(`/events/${eventId}`);
                },
              }
            );
            successCallback();
          })
          .catch(() => {
            failureCallback(
              "Error:  Registration failed. Something went wrong. Please try again later."
            );
          });
      } else {
        let errorText = "";
        errorsRes.forEach((error) => {
          errorText += `${error}<br />`;
        });
        failureCallback(errorText);
      }
    } catch (err) {
      failureCallback(
        "Error:  Registration failed. Something went wrong. Please try again later."
      );
    }
  };

  const formValidator = (newData) => {
    const abhyasisId = get(newData, "ref");
    if (abhyasisId && !validateAbhyasiId(abhyasisId)) {
      return "Abhyasi id is not valid";
    }
    return false;
  };

  useEffect(() => {
    const optFields = get(
      eventDetails,
      "event_json.widget_optional_fields",
      []
    );
    const optFieldsCheck = get(
      eventDetails,
      "event_json.widget_optional_fields_check_json",
      []
    );

    if (optFields.length || optFieldsCheck.length) {
      const eventFormFields = [...optFields, ...optFieldsCheck];
      const eventFormOptionalField = eventFormFields.map((item) => {
        if (item.field_type === "textarea") {
          return {
            name: `reg_json.${item.field_name}`,
            label: item.label,
            type: item.field_type,
            props: {
              required: item.is_required,
              multiline: true,
              rows: 2,
              gridWidth: 250,
            },
          };
        }
        if (
          ["gender", "communication_preference", "age_group"].includes(
            item.field_type
          )
        ) {
          let selectOpt = [];
          if (item.field_type === "gender") {
            selectOpt = [
              { name: "", label: "" },
              { name: "M", label: "Male" },
              { name: "F", label: "Female" },
              { name: "U", label: "Unspecified" },
            ];
          }
          if (item.field_type === "communication_preference") {
            selectOpt = [
              { name: "", label: "" },
              { name: "1", label: "Email" },
              { name: "2", label: "SMS" },
              { name: "3", label: "Email & SMS" },
            ];
          }
          if (item.field_type === "age_group") {
            selectOpt = [
              { name: 4, label: "0 - 4" },
              { name: 9, label: "5 - 9" },
              { name: 14, label: "10 - 14" },
              { name: 19, label: "15 - 19" },
              { name: 24, label: "20 - 24" },
              { name: 29, label: "25 - 29" },
              { name: 34, label: "30 - 34" },
              { name: 39, label: "35 - 39" },
              { name: 44, label: "40 - 44" },
              { name: 49, label: "45 - 49" },
              { name: 54, label: "50 - 54" },
              { name: 60, label: "60 - 64" },
              { name: 69, label: "65 - 69" },
              { name: 74, label: "70 - 74" },
              { name: 79, label: "75 - 79" },
            ];
          }
          return {
            name: item.field_name,
            label: item.label,
            type: "select",
            options: selectOpt,
            props: {
              required: item.is_required,
              gridWidth: 250,
              style: {
                border: "1px solid #dedede",
                padding: "0 5px",
                marginBottom: "10px",
              },
            },
          };
        }
        return {
          name: `reg_json.${item.field_name}`,
          label: item.label,
          type: item.field_type,
          props: {
            required: item.is_required,
          },
        };
      });

      setRegisterFormFields(regFormFields.concat(eventFormOptionalField));
    } else {
      setRegisterFormFields(regFormFields);
    }
  }, [eventId, eventDetails]);

  return (
    <div className="form-section registration-form">
      <DynamicForm
        formClassName="form"
        formLayout={null}
        defaultValues={regFormDefaults}
        formFields={registerFormFields}
        formValidator={formValidator}
        onSubmit={onSave}
        buttons={{ submitButton, discardButton }}
      />
    </div>
  );
}

RegisterForm.propTypes = {
  eventId: PropTypes.string.isRequired,
  eventDetails: PropTypes.instanceOf(Object).isRequired,
};
