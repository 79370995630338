/** @jsx jsx */
import { jsx, Box } from "theme-ui";
import { faCar } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DynamicForm from "@heartfulnessinstitute/react-hfn-forms/dist/DynamicForm";
import Layout from "../../layouts/Layout";
import registrationOptions from "../../utils/visitorRegistration";

const VisitorRegistration = ({ pnr }) => {
  return (
    <Layout sidebarVisible>
      <h2>
        {" "}
        <FontAwesomeIcon icon={faCar} color="#767596" /> &nbsp;
        Arrival/Departure
      </h2>
      <br />
      <Box>
        <small>
          <b>PNR</b>
        </small>
        <br />
        <h3>{pnr}</h3>
      </Box>
      <Box>
        <DynamicForm
          formFields={registrationOptions.fields}
          onSubmit={() => {}}
          onDiscard={() => {}}
        />
      </Box>
    </Layout>
  );
};

VisitorRegistration.propTypes = {
  pnr: PropTypes.string.isRequired,
};

export default VisitorRegistration;
