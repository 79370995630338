const generatePNR = (name) => {
  const d = new Date();
  const n = d.getTime() - 1520000000000;
  let out = "";
  // let sumrem = 0;
  let itr = n;
  while (itr > 0) {
    const div = Math.floor(itr / 26);
    const rem = itr % 26;
    out = String.fromCharCode(65 + rem) + out;
    itr = div;
    // sumrem += rem;
  }
  return `${name
    .replace("-", "")
    .replace("_", "")
    .replace(".", "")
    .replace("@", "")
    .slice(0, 2)
    .toUpperCase()}-${out.slice(0, 4)}-${out.slice(
    out.length - 4,
    out.length
  )}`;
};

export default generatePNR;
